import BinsenseStore, { presetSiteStructuresData } from '../../stores/BinsenseStore';
import { AlertHistoryData, Association, AutomationAccessory, AutomationDevicesData, BinLog, BinNote,
    CableAssociation, Command, CommunicationTable, CustomerReportData, DeviceSettingsStructure, Difference,
    FanAssociation, MyCustomersTableData, NotesTableData, ServiceRecord } from '../../types/components/ApiTypes';

export function clearPreviousAlertHistory() {
    BinsenseStore.setAlertHistoryData([] as AlertHistoryData[]);
    BinsenseStore.setAlertHistoryCount(0);
}

export function clearPreviousServiceRecords() {
    BinsenseStore.setServiceRecordsData([] as ServiceRecord[]);
}

export function clearPreviousAdvancedAssociations() {
    BinsenseStore.setCableAssociationsData([] as CableAssociation[]);
    BinsenseStore.setFanAssociationsData([] as FanAssociation[]);
    BinsenseStore.setAutomationAccessoriesData([] as AutomationAccessory[]);
}

export function clearPreviousViewDevices() {
    BinsenseStore.setBinNotesData([] as BinNote[]);
    BinsenseStore.setNotesTableData([] as NotesTableData[]);
}

export function clearPreviousCustomerCommands() {
    BinsenseStore.setCommandsData([]);
    BinsenseStore.setCommandHistoryData([] as Command[]);
    BinsenseStore.setCommandCount(0);
}

export function clearPreviousReportsLogs() {
    BinsenseStore.setBinLogsData([] as BinLog[]);
}

export function clearPreviousReports() {
    BinsenseStore.setDifferencesData([] as Difference[]);
    BinsenseStore.setAutomationDevicesData([] as AutomationDevicesData[]);
    BinsenseStore.setBinLogsData([] as BinLog[]);
    BinsenseStore.setCommunicationTableData({} as CommunicationTable);
    BinsenseStore.setCustomerReportsData([] as CustomerReportData[]);
}

export function clearPreviousDeviceSettings() {
    BinsenseStore.setDeviceTableData([] as DeviceSettingsStructure[]);
    BinsenseStore.setAssociationsData([] as Association[]);
}

export function clearPreviousCustomers() {
    BinsenseStore.setCustomerSiteData([] as MyCustomersTableData[]);
}

export function clearSiteStructures() {
    BinsenseStore.setSiteStructures(presetSiteStructuresData);
}